// This is a file that can be overridden by the application in order to override
// some of the Foundation/Decidim SCSS settings and variables after the default
// settings have been loaded.
//
// To override styles use decidim_application.scss
//
// By default this is empty.

$primary: #009790;
// $secondary: #599aa6;
// $success: #57d685;
// $warning: #ffae00;
// $alert: #ec5840;
// 
// $proposals: #238FF7;
// $actions: #57D685;
// $debates: #FA6C96;
// $meetings: #FABC6C;
// 
// $twitter: #55acee;
// $facebook: #3b5998;
// $google: #dd4b39;